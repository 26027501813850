.home {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  header {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px 0;
    display: flex;
    justify-content: flex-end;

    .header-btn {
      font-size: 1.15rem;
      font-weight: 300;
      color: #000;
      padding: 0 1rem 0 0;
      background: none;
      border: 0;
      transition: border-bottom 0.25s;

      &:last-of-type {
        padding: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 1px;
        background: #000;
        transition: width 0.5s ease-in-out;
      }

      &:hover {
        cursor: pointer;

        &::after {
          width: 100%;
        }
      }
    }
  }

  #name-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 3rem;
      line-height: 3.25rem;
      text-align: center;
      font-weight: 700;
    }

    h3 {
      font-size: 2rem;
      line-height: 2.25rem;
      text-align: center;
      font-weight: 100;
    }
  }

  #links {
    box-sizing: border-box;
    margin-top: 3rem;
    padding: 0 2rem;
    display: flex;
    justify-content: center;

    .btn-icon {
      height: 1.25rem;
      width: 1.25rem;
      object-fit: contain;
      filter: brightness(0) invert(1);
      margin-right: 0.5rem;
    }

    .btn-text {
      font-size: 1.1rem;
      font-weight: 500;
      color: #fff;
    }

    .link-btn {
      flex: 1 0 auto;
      box-sizing: border-box;
      max-width: 200px;
      margin: 0 0.5rem;
      padding: 0.6rem 1rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      &#github {
        background-color: #8a37c2;

        &:hover {
          background-color: darken($color: #8a37c2, $amount: 10%);
        }
      }

      &#linkedin {
        background-color: #2266b5;

        &:hover {
          background-color: darken($color: #2266b5, $amount: 10%);
        }
      }
    }

    .link-dropdown {
      flex: 1 0 auto;
      box-sizing: border-box;
      max-width: 200px;
      margin: 0 0.5rem;
      position: relative;

      &:hover,
      &:active {
        #link-cv-wrapper {
          max-height: 1000px;
        }

        .cv-button {
          background-color: darken($color: #c0c0c0, $amount: 10%);
        }
      }

      .cv-button {
        box-sizing: border-box;
        width: 100%;
        padding: 0.6rem 1rem;
        border-radius: 0.5rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c0c0c0;
        z-index: 1;
      }

      #link-cv-wrapper {
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 0;
        padding-top: 0.5rem;
        margin-top: -0.5rem;
        overflow: hidden;
        background-color: #f0f0f0;
        border-radius: 0 0 0.5rem 0.5rem;
        display: flex;
        flex-direction: column;
        transition: max-height 0.5s ease-in-out;

        a {
          box-sizing: border-box;
          width: 100%;
          padding: 0.5rem;
          color: #303030;
          text-align: center;
          text-decoration: none;
          border-bottom: 1px solid #d8d8d8;

          &:last-of-type {
            border: none;
          }

          &:hover {
            background-color: darken($color: #f0f0f0, $amount: 5%);
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home {
    header {
      .header-btn {
        padding: 0 0.75rem 0 0;
      }
    }
    #name-container {
      flex: 1 0 auto;

      h1 {
        font-size: 9vw;
        line-height: 9.5vw;
      }

      h3 {
        font-size: 7vw;
        line-height: 7.5vw;
      }
    }

    #links {
      width: 100%;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 1rem;
      padding: 0 1rem;

      .btn-icon {
        height: 1.25rem;
        width: 1.25rem;
        object-fit: contain;
        filter: brightness(0) invert(1);
        margin-right: 0.5rem;
      }

      .btn-text {
        font-size: 1.1rem;
        font-weight: 500;
        color: #fff;
      }

      .link-btn {
        width: 100%;
        max-width: none;
        margin: 0.25rem 0;
        padding: 0.75rem 0;
      }

      .link-dropdown {
        max-width: none;
        margin: 0.25rem 0;

        .cv-button {
          width: 100%;
          padding: 0.75em 0;
        }

        #link-cv-wrapper {
          position: relative;
          top: 0;

          a {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
